import { render, staticRenderFns } from "./activityDetail.vue?vue&type=template&id=012e3e33&scoped=true&"
import script from "./activityDetail.vue?vue&type=script&lang=js&"
export * from "./activityDetail.vue?vue&type=script&lang=js&"
import style0 from "./activityDetail.vue?vue&type=style&index=0&id=012e3e33&prod&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "012e3e33",
  null
  
)

export default component.exports